<template>
    <div class="relative flex">
        <el-input ref="searchInput" class="w-64" placeholder="Snabbsökning" @keyup.native="inputChange" @clear="inputChange" v-model="query" clearable />
        <span class="p-1">|</span>
        <el-input ref="searchInput2" class="w-64" placeholder="Snabbsökning" @keyup.native="inputChange" @clear="inputChange" v-model="query2" clearable />
    </div>
</template>
<script>
import queryString from "query-string";
export default {
    data() {
        return {
            query: "",
            query2: "",
            timer: null,
        };
    },

    created() {
        this.assignInitValues();
    },

    methods: {
        assignInitValues() {
            const { search } = queryString.parse(location.search);
            const { search2 } = queryString.parse(location.search);
            this.query = search;
            this.query2 = search2;
        },

        inputChange() {
            clearInterval(this.timer);
            this.timer = setTimeout(() => {
                this.updateUrl();
                this.$emit("change");
            }, 700);
        },

        updateUrl() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.pageNumber = 1;
            query.search = this.query;
            query.search2 = this.query2;
            this.$router.replace({ query }).catch(() => {});
        },
    },
};
</script>
