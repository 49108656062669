<template>
    <div class="flex justify-between">
        <div class="flex">
            <el-input ref="searchInput" class="w-64" placeholder="Snabbsökning" @keyup.native="inputChange" @clear="inputChange" v-model="query" clearable />
            <el-button class="ml-2" @click="showIconEditorModal()">+ Ikon</el-button>
        </div>
        <IconEditor :visible="showIconEditor" @close="showIconEditor = false" :icon-options="iconOptions" />
        <div class="flex flex-row-reverse">
            <el-button type="primary" @click="showGroupCategoryModal">Skapa kategorigrupp</el-button>
            <el-select v-model="type" @change="inputChange" class="pr-2">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.label" />
            </el-select>
            <AddAccountGroupCategory :icons="iconOptions" :visible="showAddGroupCategory" @close="showAddGroupCategory = false" @reloadlist="reloadList" />
        </div>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "../accountCategories.api";

export default {
    components: {
        IconEditor: () => import(/* webpackChunkName: "IconEditor" */ "../components/modals/IconEditor.vue"),
        AddAccountGroupCategory: () => import(/* webpackChunkName: "AddAccountGroupCategory" */ "../components/modals/AddAccountGroupCategory.vue"),
    },

    data() {
        return {
            iconOptions: [],
            query: "",
            type: "Enskildfirma",
            showAddGroupCategory: false,
            typeOptions: [
                {
                    value: 1,
                    label: "Enskildfirma",
                },
                {
                    value: 2,
                    label: "Aktiebolag  ",
                },
            ],
            showIconEditor: false,
        };
    },

    created() {
        this.assignInitValues();
    },

    methods: {
        assignInitValues() {
            const { type } = queryString.parse(location.search);
            const { search } = queryString.parse(location.search);
            this.type = type || this.type;
            this.query = search;
            this.updateUrl();
        },

        inputChange() {
            clearInterval(this.timer);
            this.timer = setTimeout(() => {
                this.updateUrl();
                this.$emit("reloadList");
            }, 700);
        },

        updateUrl() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.type = this.type;
            query.search = this.query;
            this.$router.replace({ query }).catch(() => {});
        },

        reloadList() {
            this.showAddGroupCategory = false;
            this.$emit("reloadList");
        },

        showGroupCategoryModal() {
            this.getIconOptions();
            this.showAddGroupCategory = true;
        },

        showIconEditorModal() {
            this.getIconOptions();
            this.showIconEditor = true;
        },

        async getIconOptions() {
            this.$waiting.start("loading");
            this.iconOptions = await Api.getIconOptions();
            this.$waiting.end("loading");
        },
    },
};
</script>
