<template>
    <div>
        <el-table
            ref="supplierTable"
            :row-class-name="tableRowClassName"
            :data="tableData"
            :default-sort="preSelectedSort"
            @sort-change="sortChange"
            @selection-change="handleSelectionChange"
            v-loading="$waiting.is('loading')"
            size="mini"
        >
            <el-table-column type="selection" width="40" />
            <el-table-column prop="name" label="Namn" width="290" sortable="custom" />
            <el-table-column prop="registrationNumber" label="Org nr" width="150" sortable="custom" />
            <el-table-column prop="email" label="E-post" width="170" sortable="custom" />
            <el-table-column label="Standardbokningskonto">
                <el-table-column prop="privateCompanyDefaultAccount" label="Privat" width="100" sortable="custom" />
                <el-table-column prop="stockCompanyDefaultAccount" label="Företag" width="100" sortable="custom" />
            </el-table-column>
            <el-table-column prop="invoices" label="Fakturor" width="100" sortable="custom" />
            <el-table-column width="34">
                <template slot-scope="scope">
                    <i class="fa-regular fa-file-lines cursor-pointer" @click="showSupplierInfoModal(scope.row)" />
                </template>
            </el-table-column>
            <el-table-column width="68">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="handleSelectHead(scope.row)"><i class="fa-regular fa-user-plus"/></el-button>
                </template>
            </el-table-column>
        </el-table>
        <SuppliersInfoModal :visible="showSupplierInfo" @close="showSupplierInfo = false" :source-row="selectedRow" />
    </div>
</template>
<script>
import queryString from "query-string";

export default {
    props: {
        tableData: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            sortBy: null,
            sortByType: null,
            selectedRow: null,
            selectedId: 0,
            showSupplierInfo: false,
        };
    },

    components: {
        SuppliersInfoModal: () => import(/* webpackChunkName: "SuppliersInfoModal" */ "../modals/SupplierInfoModal.vue"),
    },

    created() {
        this.assignInitValues();
    },

    methods: {
        assignInitValues() {
            const { sortBy, sortByType } = queryString.parse(location.search);
            this.sortBy = sortBy;
            this.sortByType = sortByType;
        },

        showSupplierInfoModal(row) {
            this.selectedRow = row;
            this.showSupplierInfo = true;
        },

        handleSelectionChange(val) {
            this.$emit("multipleSelection", val);
        },

        handleSelectHead(row) {
            this.selectedId = row.id;
            this.$emit("setHead", row.id);
        },

        tableRowClassName({ row }) {
            return this.selectedId == row.id ? "success-row" : "";
        },

        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("sortTypeChanged");
        },
    },

    computed: {
        preSelectedSort() {
            if (!this.sortBy) return {};
            return {
                prop: this.sortBy,
                order: this.sortByType,
            };
        },
    },
};
</script>
<style>
.el-table .success-row {
    background: #f0f9eb;
}
</style>
